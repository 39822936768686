import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import search from '../../assets/images/campaigns/search_icon.svg'
import leftangle from '../../assets/images/campaigns/leftangle.svg'
import rightangle from '../../assets/images/campaigns/rightangle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { videosRetrive } from '../../actions/menus'
import { useAlert } from 'react-alert'
import SpinnerCircular from '../overlays/SpinnerCircular'
import './dropdown.css'
import { deleteVideo } from '../../api'

const VideoLibrary = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { page } = useParams()
    const alert = useAlert()
    const [isLoading, setIsLoading] = useState(true)
    const Merchant = useSelector((state) => state.currentMerchantReducer)
    const [videosData, setvideosData] = useState({
        count: 0,
        next: null,
        previous: null,
        results: []
    })

    const [selectedVideos, setselectedVideos] = useState([])

    // bulk actions
    const switcher = useRef(null)
    const takeAction = () => {
        switch (switcher?.current?.value) {
            case "DELETE":
                handleDeleteVideos()
                break
            default:
                (() => { })()
                break
        }
    }
    const selectAllVideos = (e) => {
        if (e.target.checked) {
            setselectedVideos(videosData?.results.map(campaign => campaign?.id))
        } else {
            setselectedVideos([])
        }
    }
    const handleDeleteVideos = async () => {
        if (!selectedVideos.length > 0) {
            alert.info("Please select videos first")
        } else {
            if (window.confirm(`Delete selected video${selectedVideos.length > 1 ? 's' : ''}?`)) {
                Promise.all(
                    selectedVideos.map(id => deleteVideo(Merchant?.merchant?.slug, id))
                ).then(e => alert.success(`Deleted selected video${selectedVideos.length > 1 ? 's' : ''}`))
                setvideosData(videosData => ({ ...videosData, results: videosData?.results.filter(img => !selectedVideos.includes(img?.id)) }))
                setselectedVideos([])
            }
        }
    }
    const [itemsearch, setItemsearch] = useState("")

    // pagination
    const nextPage = () => {
        setIsLoading(true)
        navigate("./" + (parseInt(page) + 1))
    }
    const prevPage = () => {
        setIsLoading(true)
        navigate("./" + (parseInt(page) - 1))
    }

    useEffect(() => {
        if (!page) {
            navigate("1")
        } else {
            setIsLoading(true)
            dispatch(videosRetrive(setvideosData, setIsLoading, Merchant?.merchant?.slug, page))
        }
    }, [dispatch, Merchant?.merchant?.slug, page, navigate])

    return <div className="container d-flex flex-column px-3 bg-o-white pt-2">
        <div className="d-flex align-items-center justify-content-between py-3">
            <div className="fw-semibold fs-5">Video Library</div>
            <div>
                <Link className="btn bg-white border" to={"../media-library/1"}>View images</Link>
            </div>
        </div>
        {
            isLoading ?
                <SpinnerCircular /> : <>
                    {
                        videosData?.results.length > 0 ?
                            <>
                                <div className="py-2 d-flex gap-3">
                                    {/* <div className="dropdown">
                                        <button className="btn text-gray-2 rounded-perfect border bg-white shadow-sm d-flex align-items-center" type="button" id="dateRangeSelector" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={select_range} alt="" className="pe-2" height={"15px"} /> All
                                        </button>
                                        <div className="dropdown-menu shadow-sm p-0" aria-labelledby="dateRangeSelector">
                                            <Link to="" className="dropdown-item">Option 1</Link>
                                            <Link to="" className="dropdown-item">Option 2</Link>
                                        </div>
                                    </div> */}
                                    <div className="bg-white flex-grow-1 border d-flex align-items-center shadow-sm rounded-perfect">
                                        <img src={search} alt="" className="px-3 pe-2" height={"15px"} />
                                        <input type="text" style={{ outline: "none" }} onChange={(e) => setItemsearch(e.target.value)} value={itemsearch} placeholder='Search' className='border-0 w-100' />
                                    </div>
                                    {/* <div className="dropdown">
                                        <button className="btn text-gray-2 rounded-perfect border bg-white shadow-sm d-flex align-items-center" type="button" id="dateRangeSelector" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src={select_range} alt="" className="pe-2" height={"15px"} /> Post Date
                                        </button>
                                        <div className="dropdown-menu shadow-sm p-0" aria-labelledby="dateRangeSelector">
                                            <Link to="" className="dropdown-item">Option 1</Link>
                                            <Link to="" className="dropdown-item">Option 2</Link>
                                        </div>
                                    </div> */}
                                    <Link to={"../media-library/upload"} className="btn btn-primary">New entry</Link>
                                </div>
                                <div className="py-3 justify-content-between d-flex">
                                    <div className='d-flex align-items-center gap-2'>
                                        <select style={{ width: "300px" }} className="form-select rounded-perfect bg-white shadow-sm text-muted" ref={switcher}>
                                            <option value={""}>Bulk Actions</option>
                                            <option value={"DELETE"}>Delete selected</option>
                                        </select>
                                        <button className='btn bg-white shadow-sm rounded-perfect border' onClick={takeAction}>Apply</button>
                                    </div>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='px-2 text-gray-2'>{(page - 1) * 25 + 1}-{videosData.count < 25 || videosData.count < 25 * page ? videosData.count : 25 * page} of {videosData.count} entries</div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn bg-white border pb-2" disabled={page <= 1} onClick={prevPage}><img src={leftangle} alt="" /></button>
                                            <button type="button" className="btn bg-white border pb-2" disabled={!videosData.next} onClick={nextPage}><img src={rightangle} alt="" /></button>
                                        </div>
                                    </div>
                                </div>
                                <div className='my-3 rounded-2 shadow-sm border'>
                                    <table className='table m-0' cellPadding={"40px"}>
                                        <thead>
                                            <tr className='bg-light-gray fw-perfect'>
                                                <th className='ps-4 fs-6 align-middle' ><input type="checkbox" className='form-check-input' checked={selectedVideos.length === videosData?.results.length} onChange={selectAllVideos} /></th>
                                                {/* <td className='py-3'>Type</td> */}
                                                <td className='py-3'>Name</td>
                                                <td className='py-3'>Filename</td>
                                                <td className='py-3'>Merchant</td>
                                                <td className='py-3'>File</td>
                                                {/* <td className='py-3'>Date</td> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                videosData ?
                                                    videosData?.results.filter((item) => item?.name.toLowerCase().startsWith(itemsearch.toLowerCase())).map((videoData, i) => <tr key={"img" + i} className='bg-white border-0 border-bottom fs-6'>
                                                        <td className='ps-4 align-middle'><input type="checkbox" className='form-check-input' checked={selectedVideos.includes(videoData?.id)} onChange={(e) => e.target.checked ? setselectedVideos(selectedVideos => [...selectedVideos, videoData.id]) : setselectedVideos(selectedVideos => selectedVideos.filter((itemId) => itemId !== videoData.id))} /></td>
                                                        <td className='p-3 px-2 fw-perfect'><Link to={`./edit/${videoData?.id}`} className="text-decoration-none text-dark">{videoData?.name}</Link></td>
                                                        <td className='p-3 px-2'>{videoData?.file_name}</td>
                                                        <td className='p-3 px-2'>{videoData?.merchant}</td>
                                                        <td className='d-flex align-items-center'>
                                                            <div className='position-relative image-dropdown'>
                                                                <video height={"350px"} autoPlay muted controls style={{ top: "-175px", right: "40px", objectFit: "fill" }} width="350px" className='border bg-white hover-visible rounded-perfect me-2 p-2 position-absolute shadow' >
                                                                    <source src={videoData?.video} height={"350px"} />
                                                                </video>
                                                                <video src={videoData?.video} height={"40px"} width="40px" style={{ objectFit: "fill" }} className='border rounded-perfect me-2' />
                                                            </div>
                                                        </td>
                                                        {/* <td className='p-3 px-2'>260kb</td>
                                            <td className='p-3 px-2'>10.28.22</td> */}
                                                    </tr>) : <></>
                                            }

                                        </tbody>
                                    </table>
                                </div>
                                <div className="py-3 justify-content-between d-flex">
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='px-2 text-gray-2'>{(page - 1) * 25 + 1}-{videosData.count < 25 || videosData.count < 25 * page ? videosData.count : 25 * page} of {videosData.count} entries</div>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn bg-white border pb-2" disabled={page <= 1} onClick={prevPage}><img src={leftangle} alt="" /></button>
                                            <button type="button" className="btn bg-white border pb-2" disabled={!videosData.next} onClick={nextPage}><img src={rightangle} alt="" /></button>
                                        </div>
                                    </div>
                                    <button className="btn bg-white border shadow-sm">Export...</button>
                                </div>
                            </> :
                            <div className='text-center'>No videos found. <Link to={"upload"}>Upload Videos</Link></div>
                    }
                </>
        }
    </div>
}

export default VideoLibrary