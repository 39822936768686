import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { trackAdvertisement } from "../../api";

function generatePayload() {
  return {
    "userAgent": navigator.userAgent,
    "platform": navigator.platform || navigator.userAgentData.platform || navigator.vendor,
    "screenSize": {
      "width": window.outerWidth,
      "height": window.outerHeight,
    },
    "language": navigator.language,
    "timezone": new Date().getTimezoneOffset(),
    "cookiesEnabled": navigator.cookieEnabled
  };
}


const Tracker = () => {
  const { slug, advId, nextUrl } = useParams();
  useEffect(() => {
    if (slug && advId && nextUrl) {
      const payload = {
        device_info: generatePayload(),
        date: new Date(),
        time: new Date().getTime(),
      }
      trackAdvertisement(slug, advId, payload).then(() => {
        window.location.href = nextUrl;
      });
    }
  }, [slug, advId]);

  return <div className="d-flex justify-content-center gap-2 align-items-center vh-100">
    Redirecting...
    <div className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>;
};

export default Tracker;
