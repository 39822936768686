import { combineReducers } from "redux";
import authReducer from "./auth";
import currentUserReducer from "./currentUser";
import currentMerchantReducer from "./currentMerchant";
import merchantReducer from "./merchant";
import menuItems from "./menuItems";
import currentTransactions from "./transactions";
import currentCampaigns from "./campaigns";

export default combineReducers({
    authReducer, currentUserReducer, currentMerchantReducer, merchantReducer, menuItems, currentCampaigns, currentTransactions
})