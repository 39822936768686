import React, { Suspense, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { setCurrentCampaigns } from "./actions/campaignsAction";
import { setCurrentUser } from './actions/currentUser';
import { setMenuItems } from "./actions/menuItems.js";
import jwtDecode from 'jwt-decode';
import { setCurrentMerchant } from './actions/currentMerchant';
import './index.css';
import SpinnerCircular from './components/overlays/SpinnerCircular';
import VideoLibrary from './components/medialibrary/VideoLibrary';
import Tracker from './components/advertisements/Tracker';

const Dashboard = React.lazy(() => import('./Dashboard'))
const Merchant = React.lazy(() => import('./Merchant'))
const Login = React.lazy(() => import('./auth/Login'))
const Logout = React.lazy(() => import('./auth/Logout'))
const Signup = React.lazy(() => import('./auth/Signup'))
const MyAccount = React.lazy(() => import('./components/account/MyAccount'))
const Campaigns = React.lazy(() => import('./components/campaigns/Campaigns'))
const CreateCampaign = React.lazy(() => import('./components/campaigns/CreateCampaign'));
const Channels = React.lazy(() => import('./components/channels/Channels'))
const DigitalSignage = React.lazy(() => import('./components/channels/DigitalSignage'))
const OnlineOrdering = React.lazy(() => import('./components/channels/OnlineOrdering'))
const Report = React.lazy(() => import('./components/dashboard/Report'))
const UserDashboard = React.lazy(() => import('./components/dashboard/UserDashboard'))
const UserDashboardCharts = React.lazy(() => import('./components/dashboard/UserDashboardCharts'))
const ContentLibrary = React.lazy(() => import('./components/medialibrary/ContentLibrary'))
const EditImage = React.lazy(() => import('./components/medialibrary/EditImage'))
const EditVideo = React.lazy(() => import('./components/medialibrary/EditVideo'))
const UploadMedia = React.lazy(() => import('./components/medialibrary/UploadMedia'))
const Menu = React.lazy(() => import('./components/menus/Menu'))
const MenuInsights = React.lazy(() => import('./components/menus/MenuInsights'))
const MenuItem = React.lazy(() => import('./components/menus/MenuItem'))
const UploadCSV = React.lazy(() => import('./components/menus/UploadCSV'))
const ComingSoon = React.lazy(() => import('./components/overlays/ComingSoon'))
const Screen = React.lazy(() => import('./components/overlays/OnboardingScreen'))
const TemplatePreview = React.lazy(() => import('./components/templates/TemplatePreview'))
const Templates = React.lazy(() => import('./components/templates/Templates'))
const CreateTemplate = React.lazy(() => import('./components/customtemplates/CreateTemplate'))
const CustomTemplate = React.lazy(() => import('./components/customtemplates/CustomTemplate'))
const CampaignsListView = React.lazy(() => import('./components/campaigns/CampaignsListView'))
const CartItems = React.lazy(() => import('./components/campaigns/CartItems'))
const Categories = React.lazy(() => import('./components/menus/Categories'))
const Integrations = React.lazy(() => import('./components/integrations/Integrations'));
const OrderConfirmation = React.lazy(() => import('./components/integrations/OrderConfirmation'));
const IntegrationsOnlineOrdering = React.lazy(() => import('./components/integrations/IntegrationsOnlineOrdering'));
const SmartScreenAPI = React.lazy(() => import('./components/integrations/SmartScreenAPI'));
const AuthClover = React.lazy(() => import('./components/overlays/AuthClover'))
const APIDemo = React.lazy(() => import('./components/recommendations/APIDemo'))
const ActiveCampaign = React.lazy(() => import('./components/templates/ActiveCampaign'))
const Support = React.lazy(() => import('./components/support/Support'))
const Advertisements=React.lazy(()=>import('./components/advertisements/Advertisements'))
const AllAdvertisements=React.lazy(()=>import('./components/advertisements/allAdvertisements'))
const CreateAdvertisement=React.lazy(()=>import('./components/advertisements/CreateAdvertisements1'))
const ViewAdvertisement=React.lazy(()=>import('./components/advertisements/ViewAdvertisement'))

const App = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleLogout = useCallback(() => {
        dispatch({ type: 'LOGOUT' });
        dispatch(setCurrentUser(null))
        dispatch(setCurrentMerchant(null))
        dispatch(setMenuItems(null))
        dispatch(setCurrentCampaigns(null))
        navigate('/login')
    }, [dispatch, navigate])

    const User = useSelector((state) => state.currentUserReducer)

    useEffect(() => {
        const token = User?.access_token
        // check if token is not expired. if expired then logout
        // otherwise set user in the redux store
        if (token) {
            const decodedToken = jwtDecode(token)
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                handleLogout()
            }
        }
        dispatch(setCurrentUser(JSON.parse(localStorage.getItem("Profile"))))
        dispatch(setCurrentMerchant(JSON.parse(localStorage.getItem("Merchant"))))
    }, [User?.access_token, handleLogout, dispatch])

    return <Suspense fallback={<SpinnerCircular fullHeight />}>
        <Routes>
            {/* redirect to login if no user is loggged in otherwise show dashboard screen */}
            <Route path='/' element={User ? <Merchant /> : <Login />}>
                <Route path=':merchID' element={<Dashboard />}>
                    {/* dashboard */}
                    <Route path='' element={<UserDashboard />} />
                    <Route path='dashboard' element={<UserDashboard />} />
                    <Route path='dashboard/charts' element={<UserDashboardCharts />} />
                    <Route path='dashboard/report' element={<Report />} />
                    {/* campaign */}
                    <Route path='campaigns' element={<Campaigns />} >
                        <Route exact path='all' element={<CampaignsListView />} >
                            <Route exact path=':page' element={<CampaignsListView />} />
                        </Route>
                        <Route exact path='digitalsignage' element={<CampaignsListView placementType="Digital Signage" />} >
                            <Route exact path=':page' element={<CampaignsListView />} />
                        </Route>
                        <Route exact path='menuboards' element={<CampaignsListView placementType="Menu Boards" />} >
                            <Route exact path=':page' element={<CampaignsListView />} />
                        </Route>
                        <Route exact path='drivethrumenus' element={<CampaignsListView placementType="Drive Thru Menus" />} >
                            <Route exact path=':page' element={<CampaignsListView />} />
                        </Route>
                        <Route exact path='online-ordering' element={<CampaignsListView placementType="Online Ordering Applications" />} >
                            <Route exact path=':page' element={<CampaignsListView />} />
                        </Route>
                    </Route>
                    <Route path='campaigns/create' element={<CreateCampaign />} >
                        <Route path=':campaignType' element={<CreateCampaign />} />
                    </Route>
                    <Route path='cart' element={<CartItems />} />
                    <Route path='campaigns/edit/:campaignID' element={<CreateCampaign edit={true} />} />
                    {/* channels */}
                    <Route path='channels' element={<Channels />} >
                        <Route path='' element={<DigitalSignage />} />
                        <Route path='online-ordering' element={<OnlineOrdering />} />
                        <Route path='email-marketing' element={<ComingSoon />} />
                        <Route path='pop-ups' element={<ComingSoon />} />
                        <Route path='advertising' element={<ComingSoon />} />
                        <Route path='text-messaging' element={<ComingSoon />} />
                        <Route path='kiosk-software' element={<ComingSoon />} />
                    </Route>

                    <Route path='segments' element={<ComingSoon />} />
                    <Route path='transactions' element={<UploadCSV transactions={true} />} />
                    <Route path='orders' element={<ComingSoon />} >
                        <Route path=':page' element={<ComingSoon />} />
                    </Route>
                    {/* menus */}
                    <Route path='menus/create' element={<MenuItem create />} />
                    <Route path='menus' element={<Menu />} >
                        <Route path=':page' element={<Menu />} />
                    </Route>
                    <Route path='menus/insights' element={<MenuInsights />} />
                    <Route path='menus/edit/:itemID' element={<MenuItem />} />
                    <Route path='menus/upload' element={<UploadCSV />} />
                    <Route path='menus/categories' element={<Categories />} >
                        <Route path=':page' element={<Categories />} />
                    </Route>

                    {/* media library */}
                    <Route path='media-library' element={<ContentLibrary />} >
                        <Route path=':page' element={<ContentLibrary />} />
                    </Route>
                    <Route path='video-library' element={<VideoLibrary />} >
                        <Route path=':page' element={<VideoLibrary />} />
                    </Route>

                    <Route path='media-library/upload' element={<UploadMedia />} />
                    <Route path='media-library/edit/:imageID' element={<EditImage />} />
                    <Route path='video-library/edit/:videoID' element={<EditVideo />} />
                    {/* others */}
                    <Route path='integrations' element={<Integrations />} >
                        <Route path='order-confirmation' element={<OrderConfirmation />} />
                        <Route path='online-ordering' element={<IntegrationsOnlineOrdering />} />
                        <Route path='ss-api' element={<SmartScreenAPI />} />
                        <Route path=':type' element={<ComingSoon />} />
                    </Route>
                    <Route path='documentation' element={<ComingSoon />} />
                    <Route path='account' element={<MyAccount />} />
                    {/* templates */}
                    <Route path='templates/new' element={<CreateTemplate />} />
                    <Route path='templates/edit/:templateId' element={<CreateTemplate edit />} />
                    <Route path='templates' element={<Templates />} />
                    {/* <Route path='templates/:page' element={<Templates />} /> */}
                    {/* Advertisements */}
                    <Route path='advertisements' element={<Advertisements/>}>
                        <Route path='all' element={<AllAdvertisements/>}>
                        <Route exact path=':page' element={<AllAdvertisements />} />
                            </Route>
                    </Route>
                    <Route path='advertisements' element={<CreateAdvertisement/>}>
                        <Route path='create' element={<>create an advertisement</>}/>
                    </Route>
                    <Route path='advertisements/edit/:advertisementID' element={<CreateAdvertisement edit={true} />} />


                    {/* support */}
                    <Route path='support' element={<Support />} />
                </Route>
            </Route>
            <Route path='/:slug/advertisements/campaign/:advertisementcampaignID' element={<ViewAdvertisement/>}/>

            {/* recommmendations */}
            <Route path='/:slug/recommender/:id' element={<ActiveCampaign />} />

            {/* recommendations api */}
            <Route path='recommendations' element={<APIDemo />} />

            {/* auth */}
            <Route path='/signup' element={<Signup />} />
            <Route path='/login' element={<Login />} />
            <Route path='/setmerchant' element={<Screen />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/auth/clover' element={User ? <AuthClover /> : <Login />} />
            {/* Advertisement tracker */}
            <Route path='/tracker/:slug/:advId/:nextUrl' element={<Tracker />} />
            {/* previews */}
            <Route path='/preview' element={<ActiveCampaign injected />} />
            <Route path='/preview/custom' element={<CustomTemplate injected />} />
            <Route path='/templatepreview' element={<TemplatePreview />} />
        </Routes>
    </Suspense>
}

export default App