import axios from 'axios'
import { getAPIUrl } from '../components/utilities/apiHelper'

const baseURL = getAPIUrl()
// const baseURL = "//api.mysite.local:8000"
// const baseURL = "https://api.smartafyi.com/"

const API = axios.create({ baseURL: baseURL })
const noAuthAPI = axios.create({ baseURL: baseURL })

API.interceptors.request.use((req) => {
    if (localStorage.getItem('Profile')) {
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('Profile')).access_token}`
    }
    return req;
})
API.interceptors.response.use(res => res,
    (error) => {
        if (!error.response) {
            alert('NETWORK ERROR')
        } else {
            const response = error.response.data
            return Promise.reject(response)
        }
    })

export const login = (authData) => API.post('/auth/login/', authData);
export const register = (authData) => API.post('/auth/registration/', authData);
export const refreshAccessToken = (refreshToken) => API.post('/auth/refresh/', refreshToken);

export const addMerchant = (data) => API.post('/dashboard/add/merchant', data);
export const isMerchant = () => API.get(`/dashboard/is_merchant`);
export const getMerchant = () => API.get('/dashboard/merchant/');
export const getTimezones = () => noAuthAPI.get('/dashboard/tzs/');
export const updateMerchant = (slug, data, id) => API.patch(`/dashboard/${slug}/merchant/${id}/`, data);
export const updateLocation = (slug, data, id) => API.patch(`/dashboard/${slug}/location/${id}/`, data);

export const updateFranchise = (slug, data, id) => API.patch(`/dashboard/${slug}/franchise/${id}/`, data);

export const addCustomer = (data) => API.post('/dashboard/is_merchant', data);
export const updateCustomer = (id) => API.patch(`/dashboard/customer/${id}/`);
export const deleteCustomer = (id) => API.delete(`/dashboard/customer/${id}/`);
export const getAllCustomers = () => API.get('/dashboard/customer/all');

export const addCard = (data) => API.post('/dashboard/add/card', data);
export const updateCard = (id) => API.patch(`/dashboard/card/${id}/`);
export const deleteCard = (id) => API.delete(`/dashboard/card/${id}/`);
export const addCustomerCard = (data) => API.post('/dashboard/add/customercard', data);

export const addLocation = (data) => API.post('/dashboard/add/customercard', data);
export const addFranchise = (data) => API.post('/dashboard/add/franchise', data);
// export const updateFranchise = (id) => API.patch(`/dashboard/franchise/${id}/`);
export const deleteFranchise = (id) => API.delete(`/dashboard/franchise/${id}/`);

export const addPosItemCategory = (data) => API.post('/dashboard/add/positemcategory', data);
export const addPosItemStock = (data) => API.post('/dashboard/add/positemstock', data);
export const addPosItem = (data) => API.post('/dashboard/add/positem', data);

export const addPosModifierGroup = (data) => API.post('/dashboard/add/posmodifiergroup', data);
export const addPosModifier = (data) => API.post('/dashboard/add/posmodifier', data);
export const addPosOrder = (data) => API.post('/dashboard/add/posorder', data);

export const addEvent = (data) => API.post('/dashboard/add/event', data);

export const addImages = (data, setProgress, slug) => API.post(`/dashboard/${slug}/image/add/`, data, {
    onUploadProgress: setProgress
});
export const addVideos = (data, setProgress, slug) => API.post(`/dashboard/${slug}/video/add/`, data, {
    onUploadProgress: setProgress
});
export const getVideoSignedUrl = (slug, data) => API.post(`/dashboard/${slug}/video/signed-url/`, data)

export const addCSV = (data, uname, setProgress) => API.post(`/dashboard/add/positem`, data, {
    headers: {
        "Content-Type": "multipart/form-data"
    },
    onUploadProgress: setProgress
});
export const addTransactionsCSV = (data, uname, setProgress) => API.post(`/dashboard/add/transaction/`, data, {
    headers: {
        "Content-Type": "multipart/form-data"
    },
    onUploadProgress: setProgress
});

export const getMenuItem = (slug, id) => API.get(`/dashboard/${slug}/positem/${id}/`);
export const createMenuItem = (slug, data) => API.post(`/dashboard/${slug}/positem/create`, data);
export const updateMenuItem = (slug, data, id) => API.patch(`/dashboard/${slug}/positem/${id}/`, data);
export const deleteMenuItem = (slug, id) => API.delete(`/dashboard/${slug}/positem/${id}/`);
export const updatePOSCategory = (slug, data, id) => API.patch(`/dashboard/${slug}/positem/${id}/`, data);

export const getCategoryList = (slug) => API.get(`/dashboard/${slug}/positemcategory/list`);
export const addCategory = (slug, data) => API.post(`/dashboard/${slug}/positemcategory/add`, data);
export const updateCategory = (slug, id, data) => API.patch(`/dashboard/${slug}/positemcategory/${id}/`, data);
export const deleteCategory = (slug, id) => API.delete(`/dashboard/${slug}/positemcategory/${id}/`);

// search
// export const getCampaigns = (slug, page, data) => API.post(`/dashboard/${slug}/campaigns/search?page=${page}`, data);
export const getCampaigns = (slug, page) => API.get(`/dashboard/${slug}/campaigns/list?page=${page}`);
export const getPlacementCampaigns = (slug, page, query, placement) => API.post(`/dashboard/${slug}/campaigns/placementlist?page=${page}`, { query, placement });
export const getActiveCampaigns = (slug) => API.get(`/dashboard/${slug}/campaigns/active`);
export const getCampaignDetails = (slug, id) => API.get(`/dashboard/${slug}/campaigns/${id}/`);
export const createCampaign = (slug, campaignData) => API.post(`/dashboard/${slug}/campaigns/add/`, campaignData);
export const updateCampaign = (slug, id, campaignData) => API.patch(`/dashboard/${slug}/campaigns/${id}/`, campaignData);
export const updateCampaignCategory = (id, categoryData) => API.patch(`/dashboard/campaigncategory/${id}/`, categoryData);
export const updateCampaignCategories = (categoryData) => API.post(`/dashboard/campaigncategory/update`, categoryData);
export const deleteCampaignCategory = (id) => API.delete(`/dashboard/campaigncategory/${id}/`);
export const updateCampaignConstraints = (constraintsData) => API.patch(`/dashboard/campaignconstraints/update`, constraintsData);
export const deleteCampaignConstraints = (id) => API.delete(`/dashboard/campaignconstraints/${id}/`);
export const deleteCampaign = (slug, id) => API.delete(`/dashboard/${slug}/campaigns/${id}/`);

export const getMenuItems = (slug, page, data) => API.post(`/dashboard/${slug}/positem/search?page=${page}`, data);
export const getAllMenuItems = (slug) => API.get(`/dashboard/${slug}/positem/all`);
export const getTransactions = (slug, page) => API.get(`/dashboard/${slug}/transaction/list/?page=${page}`);

export const imagesRetrive = (slug, page) => API.get(`/dashboard/${slug}/image/?page=${page}`);
export const videosRetrive = (slug, page) => API.get(`/dashboard/${slug}/video/?page=${page}`);

export const getImage = (slug, id) => API.get(`/dashboard/${slug}/image/${id}/`);
export const updateImage = (slug, id, data) => API.patch(`/dashboard/${slug}/image/${id}/`, data);
export const deleteImage = (slug, id) => API.delete(`/dashboard/${slug}/image/${id}/`);
export const getVideo = (slug, id) => API.get(`/dashboard/${slug}/video/${id}/`);
export const updateVideo = (slug, id, data) => API.patch(`/dashboard/${slug}/video/${id}/`, data);
export const deleteVideo = (slug, id) => API.delete(`/dashboard/${slug}/video/${id}/`);
export const getAigenImage = (slug, prompt) => API.get(`dashboard/${slug}/image_gen/?item=${prompt}`);
export const downloadAigenImage = (slug,data) => API.post(`/dashboard/${slug}/image_gen/download/`,data);
export const menuItemImageUpdate = (slug, data, itemId) => API.post(`/dashboard/${slug}/image/update/${itemId}`, data);
export const imageUpdateField = (slug, data, imageId) => API.patch(`/dashboard/${slug}/image/update/field/${imageId}/`, data); // ImageModUpdate
export const menuItemVideoUpdate = (slug, data, itemId) => API.post(`/dashboard/${slug}/video/update/${itemId}`, data);
export const menuItemVideoHighlight = (slug, data, videoId) => API.patch(`/dashboard/${slug}/video/update/field/${videoId}/`, data); // VideoModUpdate

export const downloadMenusCSV = (slug) => window.open(new URL(`/dashboard/${slug}/positem/csv/`, API.defaults.baseURL), "_blank");

// recommendations
export const getRecommendations = (slug, id, hour) => API.get(`/dashboard/${slug}/recommender/${id}/?hour_of_day=${hour}`);

export const testingRecommendations = (slug, id, options) => API.get(`/dashboard/${slug}/recommender_test?${new URLSearchParams(options).toString()}`);
export const getDescription = (slug, options) => API.get(`/dashboard/${slug}/description_generator/?${new URLSearchParams(options).toString()}`,);

export const getDashboardData = () => API.get(`/dashboard/dashboard/data/`);
export const getCloverConfig = (slug) => API.get(`/dashboard/auth/clover/${slug}/data/`);
export const saveAuthToken = (authCode, clientId, merchantId, merchant_id_clover) => API.get(`/dashboard/auth/clover/?code=${authCode}&client_id=${clientId}&merchant_id=${merchantId}&merchant_id_clover=${merchant_id_clover}`);

export const getTemplate = (slug, id) => API.get(`/dashboard/${slug}/template/${id}/`);
export const getTemplates = (slug) => API.get(`/dashboard/${slug}/template/list/`);
export const createTemplate = (slug, data) => API.post(`/dashboard/${slug}/template/create`, data);
export const updateTemplate = (slug, id, data) => API.patch(`/dashboard/${slug}/template/${id}/`, data);
export const deleteTemplate = (slug, id) => API.delete(`/dashboard/${slug}/template/${id}/`);
export const fetchAPI=(slug)=>API.get(`/dashboard/${slug}/keys`);
export const generateAPI=(slug,data)=>API.post(`/dashboard/generate/key/${slug}`,data);
export const deleteAPI=(slug,data)=>API.post(`/dashboard/${slug}/key/delete/`,data);
export const addAdvertisementCampaign=(slug,data)=>API.post(`/dashboard/${slug}/advertisement_campaign/add/`,data)
export const viewAdvertisementCampaigns=(slug,page)=>API.get(`/dashboard/${slug}/advertisement_campaign/list/?page=${page}`)
export const viewAdvertisementCampaignDetails=(slug,id)=>API.get(`/dashboard/${slug}/advertisement_campaign/${id}`)
export const deleteAdvertisementCampaign=(slug,id)=>API.delete(`/dashboard/${slug}/advertisement_campaign/${id}`)
export const viewAdvertisementDetails=(slug,id)=>API.get(`/dashboard/${slug}/advertisements/${id}/list/`)
export const updateAdvertisements=(slug,id,data)=>API.patch(`/dashboard/${slug}/advertisements/${id}`,data);
export const updateAdvertisementCampaign=(slug,id,data)=>API.patch(`/dashboard/${slug}/advertisement_campaign/${id}`,data)
export const updateAdvertisementImage=(slug,id,data)=>API.post(`/dashboard/${slug}/advertisements/image/update/${id}`,data)
export const createAdvertisement=(slug,data)=>API.post(`/dashboard/${slug}/advertisements/add`,data)
export const deleteAdvertisement=(slug,id)=>API.delete(`/dashboard/${slug}/advertisements/${id}`)
export const trackAdvertisement=(slug,id,data)=>noAuthAPI.post(`/dashboard/advertisements/scan/${id}`,data)
export const AWSFileUpload = (signedUrl, data) => fetch(signedUrl, {
    method: "PUT",
    body: data,
    headers: {
        "Content-Type": "application/octet-stream"
    }
})


//  unsecuredAPI.request({
//     url: signedUrl,
//     method: "PUT",
//     data: data,
//     headers: {
//         "Content-Type": "application/octet-stream",
//     }
// })