import React from 'react'

const SpinnerCircular = ({ fullHeight = false }) => {
    return <div className={"d-flex justify-content-center align-items-center py-5" + (fullHeight ? " vh-100" : "")}>
        <div className="spinner-border text-primary"
            role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
}

export default SpinnerCircular