import * as api from '../api'

export const addImages = (formData, setUploadProgress, idleState, alert, slug) => async (dispatch) => {
    const uploadProgress = (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress)
    }
    try {
        console.log(formData)
        await api.addImages(formData, uploadProgress, slug)
        idleState()
        alert.success('Images uploaded!')
    } catch (error) {
        console.log(error)
        idleState()
        alert.error('Upload Failed!')
    }
}
export const addCSV = async (formData, uname, setUploadProgress, setFinished, setError = (e) => { }) => {
    const uploadProgress = (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(progress)
    }
    try {
        await api.addCSV(formData, uname, uploadProgress)
        setFinished(true)
    } catch (error) {
        console.log(error)
        setError(error)
        // alert("Upload failed, please try again!")
    }
}

export const getMenuItems = (slug, setItemsData, setIsLoading, itemsearch, page) => async (dispatch) => {
    try {
        const { data } = await api.getMenuItems(slug, page, { query: itemsearch })
        console.log(data)
        setItemsData(data)
        setIsLoading(false)
    } catch (error) {
        console.log(error)
    }
}
export const getMenuItem = (slug, id, setItemData, setIsLoading) => async (dispatch) => {
    try {
        const { data } = await api.getMenuItem(slug, id)
        setItemData(data)
        setIsLoading(false)
    } catch (error) {
        console.log(error)
        setIsLoading(false)
    }
}

export const checkMenuItems = (itemsExist) => async (dispatch) => {
    try {
        const { data } = await api.getMenuItems(100, 0)
        itemsExist(data.length > 0)
    } catch (error) {
        console.log(error)
        itemsExist(false)
    }
}
export const updateMenuItem = (slug, data, id, alert, navigate, setLoaders = (b) => {}) => async (dispatch) => {
    try {
        await api.updateMenuItem(slug, data, id)
        alert.success("Item updated!")
        setLoaders(false)
        // navigate("./../../")
    } catch (error) {
        alert.error("Failed to update!")
        setLoaders(false)
        // console.log(error)
    }
}
export const menuItemImageUpdate = (slug, data, id, alert) => async (dispatch) => {
    try {
        await api.menuItemImageUpdate(slug, data, id)
        // alert.success("Item updated!")
    } catch (error) {
        // alert.error("Failed to update!")
        // console.log(error)
    }
}
export const deleteMenuItem = (slug, id, alert, navigate) => async (dispatch) => {
    try {
        await api.deleteMenuItem(slug, id)
        alert.success("Deleted!")
        navigate("./../../")
    } catch (error) {
        alert.error("Failed to delete!")
        // console.log(error)
    }
}
export const updatePOSCategory = (slug, catData, id, alert) => async (dispatch) => {
    try {
        await api.updateCategory(slug, id, catData)
        alert.success("Category updated!")
    } catch (error) {
        // console.log(error)
        alert.error("Failed to update category!")
    }
}

export const imagesRetrive = (setImagesData, setIsLoading, slug, page) => async (dispatch) => {
    try {
        const { data } = await api.imagesRetrive(slug, page)
        setImagesData(data)
        // console.log(data)
        setIsLoading(false)
    } catch (error) {
        console.log(error)
    }
}

export const videosRetrive = (setVideosData, setIsLoading, slug, page) => async (dispatch) => {
    try {
        const { data } = await api.videosRetrive(slug, page)
        setVideosData(data)
        // console.log(data)
        setIsLoading(false)
    } catch (error) {
        console.log(error)
    }
}

export const addCategory = (slug, catData, setList, alert) => async (dispatch) => {
    try {
        const { data } = await api.addCategory(slug, catData)
        setList(categoriesList => [data, ...categoriesList])
        alert.success("Category created!")
    } catch (error) {
        // console.log(error)
        alert.error("Failed to create category!")
    }
}